import React from 'react'
import { Crud } from '../../../classes/Crud'
import Container from '../../../components/layout/Container'
import Page from '../../../components/layout/Page'
import { getInfoFinanceiraDTO } from '../../../dtos/suporte/InfoFinanceiraDTO'
import InfoFinanceiraForm from '../../../forms/suporte/InfoFinanceiraForm'
import EmpresaService from '../../../services/cadastro/EmpresaService'

export default class InfoFinanceira extends Crud {
  constructor (props) {
    super(props, getInfoFinanceiraDTO, EmpresaService, 'info-financeiras')
  }

  render () {
    const { model, dataLoaded, isNew } = this.state
    return (
      <Page>
        <Container>
          {(dataLoaded || isNew) && (
            <InfoFinanceiraForm
              model={model}
              isNew={isNew}
              onGet={this.get}
            />
          )}
        </Container>
      </Page>
    )
  }
}
