import { classNames } from 'primereact/utils'
import React from 'react'

const RequiredLabel = ({
  valid,
  label,
  required = true
}) => {
  return (
    <label
      className={
        classNames(
          { 'p-error': valid },
          `label ${required ? 'required' : ''}`
        )
      }
    >{label}</label>
  )
}

export default RequiredLabel
