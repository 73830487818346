import { Button } from 'primereact/button'
import { Divider } from 'primereact/divider'
import React, { useEffect, useState } from 'react'
import { FaPix } from 'react-icons/fa6'
import TextAreaInput from '../../components/inputs/TextAreaInput'
import TextInput from '../../components/inputs/TextInput'
import Confirm from '../../components/utils/Confirm'
import { showErrorMessage, showSuccessMessage } from '../../components/utils/Message'
import Modal from '../../components/utils/Modal'
import CommonHelper from '../../helpers/CommonHelper'
import { formatCurrencyToDecimal, formatToCurrency } from '../../helpers/formaters'
import { getStore } from '../../redux/store'
import EmpresaService from '../../services/cadastro/EmpresaService'
import CreditosDetranService from '../../services/taxaDetran/CreditosDetranService'

const InserirPixCreditoDetranModal = ({ visible, onHide }) => {
  // * estados
  const [valorCredito, setValorCredito] = useState(0)
  const [valorMinimoEstipulado, setValorMinimoEstipulado] = useState(0)

  const [exibirDadosPixCobranca, setExibirDadosPixCobranca] = useState(false)
  const [pixCopiaCola, setPixCopiaCola] = useState('')
  const [qrCodePix, setQrCodePix] = useState('')

  const [visibleConfirm, setVisibleConfirm] = useState(false)

  // * busca codigo da empresa logada
  const { codigo_emp } = getStore().empresaLogada

  // * Função que busca o valor mínimo para gerar o pix
  // * Baseado na UF do despachante ou UF do cliente
  async function buscaValoMinimoPix () {
    try {
      const { valorMinimo } = await EmpresaService.getValorMinimoPix(codigo_emp)
      setValorMinimoEstipulado(valorMinimo)
      // * Se tem valor minimo acima de 0, aparece modal de atenção para usuário.
      if (valorMinimo > 0) setVisibleConfirm(true)
    } catch (error) {
      showErrorMessage(error.message)
    }
  }

  useEffect(() => {
    // * Limpa os dados quando o modal fecha
    if (!visible) {
      setValorCredito(0)
      setValorMinimoEstipulado(0)
      setPixCopiaCola('')
      setQrCodePix('')
      setExibirDadosPixCobranca(false)
    }

    // * Ao abrir o modal e tiver codigo da empresa, busca o valor mínimo para gerar o pix
    if (visible && codigo_emp) {
      buscaValoMinimoPix()
    }
  }, [visible, codigo_emp])

  const handleGerarPixCobranca = async () => {
    // * Valida valor do crédito se é maior que valor minimo estipulado
    if (valorMinimoEstipulado && valorCredito < valorMinimoEstipulado) return showErrorMessage(`Valor mínimo de recarga é de ${formatToCurrency(valorMinimoEstipulado)}. Informe um valor acima! `)

    // * Valida valor do crédito se é maior que 0
    if (valorCredito <= 0) return showErrorMessage('Informe um valor de crédito!')

    // * Tenta gerar PIX
    try {
      const { data: { qrCodeBase64, pixCopiaECola } } = await CreditosDetranService.gerarPixCobranca(
        typeof valorCredito === 'string'
          ? valorCredito
          : valorCredito.toFixed(2)
      )
      if (qrCodeBase64 && pixCopiaECola) {
        setPixCopiaCola(pixCopiaECola)
        setQrCodePix(qrCodeBase64)
        showSuccessMessage('QR Code do PIX gerado com sucesso!')
        setExibirDadosPixCobranca(true)
      }
    } catch (error) {
      showErrorMessage(error.message || 'Erro ao gerar o PIX!')
    }
  }

  return (
    <Modal
      header={'Adicionar crédito para taxa do DETRAN'}
      width={45}
      onHide={onHide}
      visible={visible}
      footer={
        <Button
          label="Fechar"
          onClick={() => onHide()}
          className='p-button-danger'
          icon="pi pi-times"
        />
      }
    >
      <div className="formgrid grid">
        <div className="flex justify-content-center w-full">
          <div className="field flex flex-column align-items-center gap-2">
            <TextInput
              label="Valor"
              placeholder="Valor a ser creditado"
              className="inputfield w-full"
              value={formatToCurrency(valorCredito)}
              onChange={(e) => setValorCredito(formatCurrencyToDecimal(e.target.value))}
            />
            {valorMinimoEstipulado > 0 && <label style={valorCredito < valorMinimoEstipulado ? { color: 'red' } : {}}>*Valor mínimo {formatToCurrency(valorMinimoEstipulado)}*</label>}
            <Button
              label="Gerar PIX"
              onClick={() => handleGerarPixCobranca()}
              disabled={valorCredito <= 0}
              className='w-full mt-3'
              icon={<FaPix />}
            />
          </div>
        </div>

        {exibirDadosPixCobranca && (
          <div className="w-full">
            <Divider className="alert-divider" />
            <div className="flex justify-content-around align-items-center">
              <div>
                <img src={`data:image/png;base64,${qrCodePix}`} alt="QR Code" />
              </div>
              <div>
                <TextAreaInput
                  value={pixCopiaCola}
                  className="w-full textArea carentDisabled"
                  disabled={true}
                  rows={1}
                  autoResize={false}
                />
                <Button
                  icon="pi pi-copy"
                  type="button"
                  label="Copiar PIX"
                  className="edit-button ml-2 mt-2"
                  onClick={() => CommonHelper.copyToClipboard(pixCopiaCola)}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <Confirm
        visible={visibleConfirm}
        onConfirm={() => {
          setValorCredito(valorMinimoEstipulado)
          setVisibleConfirm(false)
        }}
        onCancel={() => setVisibleConfirm(false)}
        title="Atenção!"
        description={`O valor mínimo para gerar um PIX é de ${formatToCurrency(valorMinimoEstipulado)}. Deseja utilizar este valor? Não será possível realizar PIX abaixo desse limite.`}
      />
    </Modal>
  )
}

export default InserirPixCreditoDetranModal
