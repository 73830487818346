import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import 'primereact/resources/primereact.min.css'
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css'
import React, { Component, useEffect } from 'react'
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import './App.scss'
import { isLoggedIn } from './helpers/auth'
import './layout/layout.scss'

import { addLocale } from 'primereact/api'
import { Provider } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import ErrorPage from './components/error/ErrorPage'
import AppContent from './components/layout/AppContent'
import PublicContent from './components/layout/PublicContent'
import { store } from './redux/store'
import Empresa from './views/cadastro/empresa/Empresa'
import EmpresaList from './views/cadastro/empresa/EmpresaList'
import { Pessoa } from './views/cadastro/pessoa/Pessoa'
import PessoaList from './views/cadastro/pessoa/PessoaList'
import { Usuario } from './views/cadastro/usuario/Usuario'
import { UsuarioList } from './views/cadastro/usuario/UsuarioList'
import { Veiculo } from './views/cadastro/veiculos/Veiculo'
import VeiculoList from './views/cadastro/veiculos/VeiculoList'
import Configuracoes from './views/configuracoes/Configuracoes'
import HistoricoMovimentacao from './views/historico/HistoricoMovimentacao'
import HistoricoTaxaDetran from './views/historico/HistoricoSaldoDetran'
import Home from './views/home/Home'
import MeuToken from './views/integracao/MeuToken'
import EstoqueITE from './views/ite/estoque/EstoqueITE'
import { ReceberTransferenciaNaITE } from './views/ite/receberTransferencia/ReceberTransferenciaNaITE'
import { TransferenciaNaITE } from './views/ite/transferencia/TransferenciaNaITE'
import { Login } from './views/login/Login'
import { CancelarEntradaMontadora } from './views/montadora/cancelarentrada/CancelarEntradaMontadora'
import { CancelarSaidaMontadora } from './views/montadora/cancelarsaida/CancelarSaidaMontadora'
import ConfirmarEntradaMontadora from './views/montadora/confirmarEntradaMontadora/ConfirmarEntradaMontadora'
import ConfirmarSaidaMontadora from './views/montadora/confirmarSaidamontadora/ConfirmarSaidaMontadora'
import EntregaVeiculosMontadora from './views/montadora/entrega/EntregaVeiculosMontadora'
import ReceberTransferenciaIteNaMontadora from './views/montadora/transferencia/ITE/ReceberTransferenciaIteNaMontadora'
import TransferenciaIteNaMontadora from './views/montadora/transferencia/ITE/TransferenciaIteNaMontadora'
import { Registro } from './views/registro/Registro'
import ConfiguracaoModulo from './views/suporte/ConfiguracaoModulos'
import ConsultaChassi from './views/suporte/ConsultaChassi'
import EstoqueRenave from './views/suporte/EstoqueRenave'
import ListagemECRVSP from './views/suporte/ListagemECRVSP'
import MensagemGlobal from './views/suporte/MensagemGlobal'
import InfoFinanceira from './views/suporte/infoFinanceira/InfoFinanceira'
import InfoFinanceiraList from './views/suporte/infoFinanceira/InfoFinanceiraList'
import AptidaoUsados from './views/veiculosUsados/aptidaoUsados/AptidaoUsados'
import EntradaVeiculoUsado from './views/veiculosUsados/entradaVeiculoUsado/EntradaVeiculoUsado'
import EstoqueUsados from './views/veiculosUsados/estoqueUsados/EstoqueUsados'
import TransferenciaUsadoCliente from './views/veiculosUsados/transferencia/cliente/TransferenciaUsadoCliente'
import AutorizarTransferenciaUsadosEstabelecimento from './views/veiculosUsados/transferencia/estabelecimento/AutorizarTransferenciaUsadosEstabelecimento'
import { ReceberTransferenciaUsadosEstabelecimento } from './views/veiculosUsados/transferencia/estabelecimento/ReceberTransferenciaUsadosEstabelecimento'
import TransferenciaUsadoFilial from './views/veiculosUsados/transferencia/filial/TransferenciaUsadoFilial'
import EntregaVeiculosConcessionaria from './views/vendaDireta/EntregaVeiculosConcessionaria'
import ImpressaoAtpve from './views/zeroKm/atpv-e/ImpressaoAtpve'
import { CancelarEntrada } from './views/zeroKm/cancelarEntrada/CancelarEntrada'
import { CancelarSaida } from './views/zeroKm/cancelarSaida.js/CancelarSaida'
import ConfirmarEntradaZeroKm from './views/zeroKm/confirmacoes/confirmarEntradaZeroKm/ConfirmarEntradaZeroKm'
import ConfirmarSaidaZeroKm from './views/zeroKm/confirmacoes/confirmarSaidaZeroKm/ConfirmarSaidaZeroKm'
import ImportarXmls from './views/zeroKm/importarXmls/ImportarXmls'
import LoteXml from './views/zeroKm/importarXmls/LoteXml'
import { ReceberTransferenciaITE } from './views/zeroKm/receberTransferencia/ITE/ReceberTransferenciaITE'
import { ReceberTransferenciaEstabelecimento } from './views/zeroKm/receberTransferencia/estabelecimento/ReceberTransferenciaEstabelecimento'
import { TransferenciaITE } from './views/zeroKm/transferencia/ITE/TransferenciaITE'
import { TransferenciaEstabelecimento } from './views/zeroKm/transferencia/estabelecimento/TransferenciaEstabelecimento'
import { TransferenciaFilial } from './views/zeroKm/transferencia/filial/TransferenciaFilial'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const history = useHistory()

  useEffect(() => {
    // Verifica se o usuário está logado
    if (!isLoggedIn()) {
      const { pathname, search } = rest.location
      const currentUrl = `${pathname}${search}`
      const storedUrl = localStorage.getItem('redirectAfterLogin')

      // Salva a URL no localStorage se ainda não estiver armazenada
      if (!storedUrl) {
        localStorage.setItem('redirectAfterLogin', currentUrl)
      }

      // Redireciona para a página de login
      history.push('/login')
    }
  }, [history, rest.location])

  if (!isLoggedIn()) {
    // Evita renderizar o conteúdo protegido enquanto redireciona
    return null
  }

  // Retorna a renderização condicional, dependendo do login
  return (
    <Route
      {...rest}
      render={props => {
        // Se estiver logado, renderiza o componente protegido
        return (
          <AppContent {...props}>
            <Component {...props} />
          </AppContent>
        )
      }}
    />
  )
}

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => {
    if (isLoggedIn() && !props.location.pathname.includes('registro')) {
      return (
        <AppContent {...props}>
          <Redirect to={{ pathname: '/home', state: { from: props.location } }} />
        </AppContent>
      )
    }
    return (
      <PublicContent>
        <Component {...props} />
      </PublicContent>
    )
  }} />
)

addLocale('br', {
  dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sabado'],
  dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
  dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
  monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
  monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
  today: 'Hoje',
  clear: 'Limpar'
})

class App extends Component {
  render () {
    return (
      <Provider store={store}>
        <Router>
          <Switch>
            <PublicRoute exact path="/login" component={Login} />
            <PublicRoute exact path="/registro/:nomeEmpDespachante/:codigoEmpDespachante/:codigoConvite" component={Registro} />
            <PrivateRoute exact path="/registro" component={Registro} />
            <PrivateRoute exact path="/" component={Home} />
            <PrivateRoute exact path="/home" component={Home} />
            <PrivateRoute exact path="/importar-xmls" component={ImportarXmls} />
            <PrivateRoute exact path="/lote-xml/:id" component={LoteXml} />
            <PrivateRoute exact path="/confirmar-entrada-zero" component={ConfirmarEntradaZeroKm} />
            <PrivateRoute exact path="/confirmar-saida-zero" component={ConfirmarSaidaZeroKm} />
            <PrivateRoute exact path="/atpv-e" component={ImpressaoAtpve} />
            <PrivateRoute exact path="/cancelar-saida-zero" component={CancelarSaida} />
            <PrivateRoute exact path="/cancelar-entrada-zero" component={CancelarEntrada} />
            <PrivateRoute exact path="/transferencia-entre-filiais" component={TransferenciaFilial} />
            <PrivateRoute exact path="/transferencia-entre-estabelecimentos" component={TransferenciaEstabelecimento} />
            <PrivateRoute exact path="/receber-transferencia-estabelecimentos" component={ReceberTransferenciaEstabelecimento} />
            <PrivateRoute exact path="/transferencia-para-ITE" component={TransferenciaITE} />
            <PrivateRoute exact path="/receber-transferencia-ITE" component={ReceberTransferenciaITE} />
            <PrivateRoute exact path="/historico-movimentacao" component={HistoricoMovimentacao} />
            <PrivateRoute exact path="/historico-taxa-detran" component={HistoricoTaxaDetran} />
            <PrivateRoute exact path="/pessoas" component={PessoaList} />
            <PrivateRoute exact path="/pessoa" component={Pessoa} />
            <PrivateRoute exact path="/pessoa/:id" component={Pessoa} />
            <PrivateRoute exact path="/veiculos" component={VeiculoList} />
            <PrivateRoute exact path="/veiculo/:tipoVeiculo" component={Veiculo} />
            <PrivateRoute exact path="/veiculo/:tipoVeiculo/:id" component={Veiculo} />
            <PrivateRoute exact path="/empresas" component={EmpresaList} />
            <PrivateRoute exact path="/empresa" component={Empresa} />
            <PrivateRoute exact path="/empresa/:id" component={Empresa} />
            <PrivateRoute exact path="/usuarios" component={UsuarioList} />
            <PrivateRoute exact path="/usuario" component={Usuario} />
            <PrivateRoute exact path="/usuario/:id" component={Usuario} />
            <PrivateRoute exact path="/estoque-veiculos-usados" component={EstoqueUsados} />
            <PrivateRoute exact path="/entrada-veiculo-usado/:tipoEntrada" component={EntradaVeiculoUsado} />
            <PrivateRoute exact path="/transferir-veiculo-usado-para-cliente/" component={TransferenciaUsadoCliente} />
            <PrivateRoute exact path="/transferir-veiculo-usado-para-filial/" component={TransferenciaUsadoFilial} />
            <PrivateRoute exact path="/transferir-veiculo-usado-para-estabelecimento" component={AutorizarTransferenciaUsadosEstabelecimento} />
            <PrivateRoute exact path="/receber-transferencia-usados-estabelecimentos" component={ReceberTransferenciaUsadosEstabelecimento} />
            <PrivateRoute exact path="/aptidao-usados" component={AptidaoUsados} />
            <PrivateRoute exact path="/estoque-ite" component={EstoqueITE} />
            <PrivateRoute exact path="/receber-transferencia-na-ITE" component={ReceberTransferenciaNaITE} />
            <PrivateRoute exact path="/transferencia-na-ITE" component={TransferenciaNaITE} />
            <PrivateRoute exact path="/confirmar-saida-montadora" component={ConfirmarSaidaMontadora} />
            <PrivateRoute exact path="/cancelar-entrada-montadora" component={CancelarEntradaMontadora} />
            <PrivateRoute exact path="/cancelar-saida-montadora" component={CancelarSaidaMontadora} />
            <PrivateRoute exact path="/entrega-de-veiculos-montadora" component={EntregaVeiculosMontadora} />
            <PrivateRoute exact path="/entrega-de-veiculos-concessionaria" component={EntregaVeiculosConcessionaria} />
            <PrivateRoute exact path="/confirmar-entrada-montadora" component={ConfirmarEntradaMontadora} />
            <PrivateRoute exact path="/transferencia-para-ITE-na-montadora" component={TransferenciaIteNaMontadora} />
            <PrivateRoute exact path="/receber-transferencia-ITE-na-montadora" component={ReceberTransferenciaIteNaMontadora} />
            <PrivateRoute exact path="/atpv-e/montadora" component={ImpressaoAtpve} />
            <PrivateRoute exact path="/configuracoes" component={Configuracoes} />
            <PrivateRoute exact path="/mensagem-global/:id" component={MensagemGlobal} />
            <PrivateRoute exact path="/estoque-renave" component={EstoqueRenave} />
            <PrivateRoute exact path="/consultar-chassi" component={ConsultaChassi} />
            <PrivateRoute exact path="/ListagemECRVSP" component={ListagemECRVSP} />
            <PrivateRoute exact path="/info-financeiras" component={InfoFinanceiraList} />
            <PrivateRoute exact path="/info-financeira" component={InfoFinanceira} />
            <PrivateRoute exact path="/info-financeira/:id" component={InfoFinanceira} />
            <PrivateRoute exact path="/configuracao-modulos" component={ConfiguracaoModulo} />
            <PrivateRoute exact path="/meu-token" component={MeuToken} />
            <Route path="*" render={props => <ErrorPage throughRoute {...props} />} />
          </Switch>
        </Router>
      </Provider>
    )
  }
}

export default App
