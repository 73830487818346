import { Column } from 'primereact/column'
import { classNames } from 'primereact/utils'
import React from 'react'
import List from '../../../classes/List'
import Container from '../../../components/layout/Container'
import Page from '../../../components/layout/Page'
import DataList from '../../../components/utils/DataList'
import { getInfoFinanceiraDTO } from '../../../dtos/suporte/InfoFinanceiraDTO'
import CommonHelper from '../../../helpers/CommonHelper'
import EmpresaService from '../../../services/cadastro/EmpresaService'

export default class InfoFinanceiraList extends List {
  constructor (props) {
    super(
      props,
      getInfoFinanceiraDTO,
      EmpresaService,
      'info-financeira',
      'codigo_emp',
      'ativas'
    )

    this.state = {
      ...this.state
    }

    this.setColumns([
      {
        key: 'codigo_emp',
        component: (
          <Column
            key='codigo_emp'
            className="p-col-1"
            field="codigo_emp"
            header="Código"
            sortable
            headerClassName="codigo_emp" />
        )
      },
      {
        key: 'nomraz_emp',
        component: (
          <Column
            key='nomraz_emp'
            className="p-col-4"
            field="nomraz_emp"
            header="Nome Razão"
            sortable
            headerClassName="nomraz_emp" />
        )
      },
      {
        key: 'nomfan_emp',
        component: (
          <Column
            key='nomfan_emp'
            className="p-col-4"
            field="nomfan_emp"
            header="Nome Fantasia"
            sortable
            headerClassName="nomfan_emp" />
        )
      },
      {
        key: 'cnpj_emp',
        component: (
          <Column
            key='cnpj_emp'
            className="p-col-2"
            field="cnpj_emp"
            body={(data) => CommonHelper.formatDocument(data.cnpj_emp)}
            header="CNPJ"
            sortable
            headerClassName="cnpj_emp" />
        )
      }
    ])
  }

  render () {
    const { rows, totalRecords, first, page, list, selected } = this.state

    const onSelectOption = (option) => {
      let { filter } = this.state
      this.setState({ opcaoSelecionada: option })
      filter.ativo_emp = option
      this.setState({ filter }, this.onFilter)
    }

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Informações Financeiras - Empresas</h4>
        </div>
        <div className="table-options-container">
          <div className="table-options">
            <div className={classNames({ active: this.state.opcaoSelecionada === 'ativa' }, 'filter-option')}
              onClick={() => onSelectOption('ativa')}>
              <span className="option-label">Ativas</span>
              <div className="option-quantity">
                {this.state.quantidades?.quantidadeAtivas}
              </div>
            </div>
            <div className={classNames({ active: this.state.opcaoSelecionada === 'inativa' }, 'filter-option')}
              onClick={() => onSelectOption('inativa')}>
              <span className="option-label">Inativas</span>
              <div className="option-quantity">
                {this.state.quantidades?.quantidadeInativas}
              </div>
            </div>
          </div>
        </div>
        <Container>
          <DataList
            data={list}
            selected={selected}
            rows={rows}
            onSelect={this.onSelect}
            totalRecords={totalRecords}
            first={first}
            onPage={this.onPageChange}
            page={page}
            onRowSelect={(e) => this.onView(e.data)}
            showFilter
            filterPlaceholder="Procurar empresas"
            filterName="descricaoFiltro"
            responsive
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
          >
            {this.columns.map((column) => column.component)}
          </DataList>
        </Container>
      </Page>
    )
  }
}
