export const getInfoFinanceiraDTO = (data = {}) => {
  return {
    codigoEmp: data.codigo_emp || null,
    nomrazEmp: data.nomraz_emp || null,
    nomfanEmp: data.nomfan_emp || null,
    ativoEmp: data.ativo_emp || false, // manter false para que as novas empresas fiquem inativas para evitar cobranças indevidas
    bloqfinEmp: data.bloqfin_emp || false,
    datdesEmp: (data?.datdes_emp != null ? new Date(data?.datdes_emp) : null),
    dahbloqfinEmp: (data?.dahbloqfin_emp != null ? new Date(data?.dahbloqfin_emp) : null),
    cnpjEmp: data.cnpj_emp || '',
    cnpjcoddespEmp: data.coddesp_emp || null,
    empfatEmp: data.empfat_emp || null,
    empfatautEmp: data.empfataut_emp,
    custranovCte: data?.custoTransacaoEmp?.custranov_cte || 0,
    custrausaCte: data?.custoTransacaoEmp?.custrausa_cte || 0,
    qtdminnovCte: data?.custoTransacaoEmp?.qtdminnov_cte || 0,
    qtdminusaCte: data?.custoTransacaoEmp?.qtdminusa_cte || 0,
    nomresfinEmp: data.nomresfin_emp || null,
    emaresfinEmp: data.emaresfin_emp || null,
    telresfinEmp: data.telresfin_emp || null,
    coddespEmp: data.coddesp_emp || null,
    empFaturamentoEmp: data.empFaturamentoEmp || null
  }
}

export const postInfoFinanceiraDTO = (data = {}) => {
  return {
    codigo_emp: data.codigoEmp || null,
    nomraz_emp: data.nomrazEmp || null,
    nomfan_emp: data.nomfanEmp || null,
    ativo_emp: data.ativoEmp || false,
    bloqfin_emp: data.bloqfinEmp || false,
    empfataut_emp: data.empfatautEmp,
    cnpj_emp: data.cnpjEmp || null,
    coddesp_emp: data.cnpjcoddespEmp || null,
    empfat_emp: data.empfatEmp || null,
    custranov_cte: data.custranovCte || 0,
    custrausa_cte: data.custrausaCte || 0,
    qtdminnov_cte: data.qtdminnovCte || 0,
    qtdminusa_cte: data.qtdminusaCte || 0,
    nomresfin_emp: data.nomresfinEmp || null,
    emaresfin_emp: data.emaresfinEmp || null,
    telresfin_emp: data.telresfinEmp || null,
    coddesp_emp: data.coddespEmp || null
  }
}
