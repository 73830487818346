import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import DateInput from '../../../../components/inputs/DateInput'
import NumberInput from '../../../../components/inputs/NumberInput'
import TextInput from '../../../../components/inputs/TextInput'
import { showErrorMessage, showSuccessMessage, showWarnMessage } from '../../../../components/utils/Message'
import Modal from '../../../../components/utils/Modal'
import { converterPlacaMercosul, formatCnpj, formatPlaca } from '../../../../helpers/formaters'
import AutorizacaoTransferenciaService from '../../../../services/veiculosUsados/AutorizacaoTransferenciaService'
import CheckBoxInput from '../../../../components/inputs/CheckBoxInput'

const ReceberTransferenciaUsadosEstabelecimentoModal = ({ visible, onHide, veiculoParaReceber, getAll }) => {
  const [dataTransferencia, setDataTransferencia] = useState(new Date())
  const [renavam, setRenavam] = useState(veiculoParaReceber.renavam || '')
  const [kmAtual, setKmAtual] = useState(veiculoParaReceber.quilometragemHodometro || 0)
  const [deveConverterPlaca, setDeveConverterPlaca] = useState(false)

  useEffect(() => {
    if (veiculoParaReceber) {
      setRenavam(veiculoParaReceber.renavam)
      setKmAtual(veiculoParaReceber.quilometragemHodometro)
    }
  }, [visible])

  function hideModal () {
    setRenavam('')
    setKmAtual(0)
    setDeveConverterPlaca(false)
    onHide()
  }

  const handleReceberVeiculo = async () => {
    try {
      if (!renavam) {
        showWarnMessage('Por favor informe um renavam válido!')
        return false
      }

      const veiculo = {
        ...veiculoParaReceber,
        renavam,
        quilometragemHodometro: kmAtual
      }

      await AutorizacaoTransferenciaService.receberTransferenciaEstabelecimento(dataTransferencia, veiculo, veiculoParaReceber.cnpjEstabelecimentoAutorizador)
      hideModal()
      await getAll()
      showSuccessMessage('Veículo transferido com sucesso')
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao transferir o veículo!')
    }
  }

  const trocarTipoPlacaMercosul = async () => {
    setDeveConverterPlaca(!deveConverterPlaca)
    const placaNova = converterPlacaMercosul(veiculoParaReceber.placaVeiculo)
    veiculoParaReceber.placaVeiculo = placaNova
  }

  return (
    <Modal
      header="Recebimento de transferência usados"
      width={55}
      onHide={hideModal}
      visible={visible}
      footer={
        <Button
          label="Confirmar"
          icon="pi pi-check"
          onClick={() => handleReceberVeiculo()}
        />
      }
    >
      <div className="formgrid grid">
        <div className="field col-12">
          <TextInput
            label="Estabelecimento Autorizador"
            placeholder="Estabelecimento"
            className="inputfield w-full"
            value={`${formatCnpj(veiculoParaReceber.cnpjEstabelecimentoAutorizador)} - ${veiculoParaReceber.nomeEstabelecimentoAutorizador}`}
            disabled={true}
          />
        </div>
        <div className="field col-12 md:col-3">
          <TextInput
            label="Veículo"
            className="inputfield w-full"
            value={formatPlaca(veiculoParaReceber.placaVeiculo)}
            disabled={true}
          />
        </div>
        <div className="field col-12 md:col-9 flex align-items-center">
          <CheckBoxInput
            label="Converter tipo de placa"
            value={deveConverterPlaca}
            checked={deveConverterPlaca}
            onChange={() => trocarTipoPlacaMercosul()}
          />
        </div>
        <div className="field col-12 md:col-3">
          <TextInput
            label="Renavam"
            placeholder="Renavam"
            value={renavam}
            onChange={(e) => setRenavam(e.target.value)}
            className="inputfield w-full"
            maxLength={50}
          />
        </div>
        <div className="field col-12 md:col-3">
          <NumberInput
            label="Km Atual"
            placeholder="KM Atual"
            value={kmAtual}
            onChange={(e) => setKmAtual(e.value)}
            className="inputfield w-full"
          />
        </div>
        <div className="field col-12 md:col-6">
          <DateInput
            label="Data transferência"
            placeholder="Data da transferência"
            className="inputfield w-full"
            onChange={(e) => setDataTransferencia(e.value)}
            value={dataTransferencia}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ReceberTransferenciaUsadosEstabelecimentoModal
