import * as Yup from 'yup'

export const InfoFinanceiraValidator = Yup.object().shape({
  nomrazEmp: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  nomfanEmp: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  cnpjEmp: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  custranovCte: Yup.number()
    .nullable()
    .required('Este campo é obrigatório!'),
  custrausaCte: Yup.number()
    .nullable()
    .required('Este campo é obrigatório!'),
  qtdminnovCte: Yup.number()
    .nullable()
    .required('Este campo é obrigatório!'),
  qtdminusaCte: Yup.number()
    .nullable()
    .required('Este campo é obrigatório!')

})
