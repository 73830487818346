import { Checkbox } from 'primereact/checkbox'
import { Fieldset } from 'primereact/fieldset'
import { Tooltip } from 'primereact/tooltip'
import { classNames } from 'primereact/utils'
import React, { useEffect, useState } from 'react'
import ReactInputMask from 'react-input-mask'
import DateInput from '../../components/inputs/DateInput'
import SearchInput from '../../components/inputs/SearchInput'
import TextInput from '../../components/inputs/TextInput'
import FieldErrorMessage from '../../components/utils/FieldErrorMessage'
import RequiredLabel from '../../components/utils/RequiredLabel'
import { getEmpresaDTO } from '../../dtos/cadastro/EmpresaDTO'
import CommonHelper from '../../helpers/CommonHelper'
import { formatCpfOrCnpj, formatCurrencyToDecimal, formatToCurrency } from '../../helpers/formaters'
import { useValidateInput } from '../../helpers/useValidateInput'
import EmpresaService from '../../services/cadastro/EmpresaService'

// * Componente de CNPJ com MASK
function SancesInputCFPCNPJmask ({
  value = '',
  isFormFieldValid,
  onChange,
  onBlur,
  disabled,
  name,
  placeholder
}) {
  // * estado de controle da mascara e validação para ver se é cpf ou cnpj
  const [mask, setMask] = useState(null)
  const isEmpDesp = CommonHelper.isEmpresaDespachante()
  useEffect(async () => {
    if (!isEmpDesp) {
      setMask('99.999.999/9999-99')
    } else {
      let cpfCnj = value || ''
      if (cpfCnj.length === 14) {
        setMask('99.999.999/9999-99')
      } else {
        setMask('999.999.999-99')
      }
    }
  }, [])

  return (
    <ReactInputMask
      className={classNames({ 'p-invalid': isFormFieldValid }, 'p-inputtext p-component p-filled inputfield w-full')}
      mask={mask}
      maskChar="_"
      placeholder={placeholder || !isEmpDesp ? 'CNPJ' : 'CPF/CNPJ'}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
    />
  )
}

// * Componente principal
const DadosPrincInfoFinanceira = ({
  form,
  edicaoHabilitada,
  empFaturamentoSelecionada,
  setEmpFaturamentoSelecionada
}) => {
  // * controle dos campos do form e verifica se empresa é despachante
  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(form)
  const setFieldTouched = (e) => form.setFieldTouched(e.target.name)
  const isEmpDesp = CommonHelper.isEmpresaDespachante()

  // * estado e função para controle de sugestão ao escreve no selecionar empresa de faturamento
  const [sugestaoEmpFaturamento, setSugestaoEmpFaturamento] = useState([])
  async function sugerirEmpFaturamento () {
    try {
      const empresaService = new EmpresaService()
      const { data: empsFaturamento } = await empresaService.filter(
        `descricaoFiltro=${empFaturamentoSelecionada}`
      )
      if (empsFaturamento.length <= 0) {
        showWarnMessage('Empresa de faturamento não encontrada!')
      }
      setSugestaoEmpFaturamento(empsFaturamento)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir uma empresa de faturamento!')
    }
  }

  // * Seta string ou objeto
  function handleChangeOrSelectEmpFaturamento (e) {
    // * valor em string para função sugerirEmpFaturamento
    if (typeof e.value === 'string') {
      setEmpFaturamentoSelecionada(e.value)
      return
    }

    // * Confere se o objeto ja existe no data
    // * Seta objeto ja existente ou altera para novo
    const codigoEmpFatJaInserido = form?.values?.empfatEmp
    const empFatJaEstaNoData = codigoEmpFatJaInserido === e.value.codigo_emp
    if (!empFatJaEstaNoData) setEmpFaturamentoSelecionada({
      codigo_emp: e.value.codigo_emp,
      nomfan_emp: e.value.nomfan_emp,
      cnpj_emp: e.value.cnpj_emp
    })
  }

  return (
    <div className="formgrid grid">

      {/* FIRST LINE */}

      <div className="field col-12 md:col-2 md:mb-5">
        <RequiredLabel label={!isEmpDesp ? 'CNPJ' : 'CPF/CNPJ'} valid={isFormFieldValid('cnpjEmp')}/>
        <SancesInputCFPCNPJmask
          name="cnpjEmp"
          value={form.values.cnpjEmp}
          formFieldValid={'cnpjEmp'}
          onChange={() => {}}
          onBlur={() => {}}
          disabled={true}
          isFormFieldValid={isFormFieldValid('cnpjEmp')}
          className={classNames({ 'p-invalid': isFormFieldValid('cnpjEmp') }, 'p-inputtext p-component p-filled inputfield w-full')}
        />
        <FieldErrorMessage message={getFormErrorMessage('cnpjEmp')}/>
      </div>
      <div className="field col-12 md:col-5 md:mb-5">
        <RequiredLabel label="Nome/Razão Social" valid={isFormFieldValid('nomrazEmp')}/>
        <TextInput
          name="nomrazEmp"
          placeholder="Nome/Razão Social"
          value={form.values.nomrazEmp}
          className={classNames({ 'p-invalid': isFormFieldValid('nomrazEmp') }, 'inputfield w-full')}
          onChange={() => {}}
          onBlur={() => {}}
          disabled={true}
        />
        <FieldErrorMessage message={getFormErrorMessage('nomrazEmp')}/>
      </div>
      <div className="field col-12 md:col-5 md:mb-5">
        <RequiredLabel label="Nome fantasia" valid={isFormFieldValid('nomfanEmp')}/>
        <TextInput
          name="nomfanEmp"
          placeholder="Nome fantasia"
          value={form.values.nomfanEmp}
          className={classNames({ 'p-invalid': isFormFieldValid('nomfanEmp') }, 'inputfield w-full')}
          onChange={() => {}}
          onBlur={() => {}}
          disabled={true}
        />
        <FieldErrorMessage message={getFormErrorMessage('nomfanEmp')}/>
      </div>

      {/* NEW LINE */}

      <div className="field col-12 md:col-3 md:mb-5">
        <RequiredLabel label="Mínimo de transação (Novos)" valid={isFormFieldValid('qtdminnovCte')}/>
        <TextInput
          name="qtdminnovCte"
          placeholder="Mínimo de transação (Novos)"
          maxLength={10}
          className={classNames({ 'p-invalid': isFormFieldValid('qtdminnovCte') }, 'inputfield w-full')}
          value={form.values.qtdminnovCte}
          onChange={(e) =>
            Number(e.target.value) >= 0 ? form.handleChange(e) : undefined
          }
          disabled={!edicaoHabilitada}
          onBlur={setFieldTouched}
        />
        <FieldErrorMessage message={getFormErrorMessage('qtdminnovCte')}/>
      </div>
      <div className="field col-12 md:col-3 md:mb-5">
        <RequiredLabel label="Mínimo de transação (Usados)" valid={isFormFieldValid('qtdminusaCte')}/>
        <TextInput
          placeholder="Mínimo de transação (Usados)"
          name="qtdminusaCte"
          maxLength={10}
          className={classNames({ 'p-invalid': isFormFieldValid('qtdminusaCte') }, 'p-inputtext p-component p-filled inputfield w-full')}
          value={form.values.qtdminusaCte}
          onChange={(e) =>
            Number(e.target.value) >= 0 ? form.handleChange(e) : undefined
          }
          disabled={!edicaoHabilitada}
          onBlur={setFieldTouched}
        />
        <FieldErrorMessage message={getFormErrorMessage('qtdminusaCte')}/>
      </div>
      <div className="field checkbox-container md:col-2 md:mb-5 col-4">
        <Checkbox
          name="ativoEmp"
          value={form.values.ativoEmp}
          onChange={form.handleChange}
          checked={form.values.ativoEmp}
          disabled={!edicaoHabilitada}
        />
        <label className="p-checkbox-label mb-0 pl-3">Ativo</label>
      </div>
      <div className="field md:col-4 md:mb-5 col-8">
        <DateInput
          label="Data de desativação"
          placeholder="Data de desativação"
          name="datdesEmp"
          value={form.values.datdesEmp}
          disabled={'disabled'}
          className="inputfield w-full"
        />
      </div>

      {/* NEW LINE */}

      <div className="field col-12 md:col-3 md:mb-5">
        <RequiredLabel label="Custo de transação (Novos)" valid={isFormFieldValid('custranovCte')}/>
        <TextInput
          placeholder="Custo de transação (Novos)"
          name="custranovCte"
          className={classNames({ 'p-invalid': isFormFieldValid('custranovCte') }, 'p-inputtext p-component p-filled inputfield w-full')}
          value={formatToCurrency(form.values.custranovCte)}
          onChange={(e) => {
            e.target.value = formatCurrencyToDecimal(e.target.value)
            if (Number(e.target.value) >= 0) return form.handleChange(e)
          }
          }
          disabled={!edicaoHabilitada}
          onBlur={setFieldTouched}
        />
        <FieldErrorMessage message={getFormErrorMessage('custranovCte')}/>
      </div>
      <div className="field col-12 md:col-3 md:mb-5">
        <RequiredLabel label="Custo de transação (Usados)" valid={isFormFieldValid('custrausaCte')}/>
        <TextInput
          placeholder="Custo de transação (Usados)"
          name="custrausaCte"
          className={classNames({ 'p-invalid': isFormFieldValid('custrausaCte') }, 'p-inputtext p-component p-filled inputfield w-full')}
          value={formatToCurrency(form.values.custrausaCte)}
          onChange={(e) => {
            e.target.value = formatCurrencyToDecimal(e.target.value)
            if (Number(e.target.value) >= 0) return form.handleChange(e)
          }
          }
          disabled={!edicaoHabilitada}
          onBlur={setFieldTouched}
        />
        <FieldErrorMessage message={getFormErrorMessage('custrausaCte')}/>
      </div>
      <div className="field checkbox-container md:col-2 md:mb-5 col-4">
        <Checkbox
          name="bloqfinEmp"
          value={form.values.bloqfinEmp}
          onChange={form.handleChange}
          checked={form.values.bloqfinEmp}
          disabled={!edicaoHabilitada}
        />
        <label className="p-checkbox-label mb-0 pl-3">Bloqueio Financeiro</label>
      </div>
      <div className="field md:col-4 md:mb-5 col-8">
        <DateInput
          label="Data de bloqueio"
          placeholder="Data de bloqueio"
          name="dahbloqfinEmp"
          value={form.values.dahbloqfinEmp}
          disabled={'disabled'}
          className="inputfield w-full"
        />
      </div>

      {/* NEW LINE */}

      <div className="field col-12 md:col-5">
        <Fieldset legend="Responsável pelo financeiro">
          <div className="field col-12">
            <TextInput
              value={form.values.nomresfinEmp}
              name="nomresfinEmp"
              placeholder="Nome"
              label="Nome"
              className="inputfield w-full"
              onChange={form.handleChange}
              onBlur={setFieldTouched}
              disabled={!edicaoHabilitada}
            />
          </div>
          <div className="field col-12">
            <TextInput
              value={form.values.emaresfinEmp}
              name="emaresfinEmp"
              placeholder="Email"
              label="Email"
              className="inputfield w-full"
              onChange={form.handleChange}
              onBlur={setFieldTouched}
              disabled={!edicaoHabilitada}
            />
          </div>
          <div className="field col-12">
            <TextInput
              mask="(99) 99999-999?9"
              value={form.values.telresfinEmp}
              name="telresfinEmp"
              placeholder="Telefone"
              label="Telefone"
              className="inputfield w-full"
              onChange={form.handleChange}
              onBlur={setFieldTouched}
              disabled={!edicaoHabilitada}
            />
          </div>
        </Fieldset>
      </div>

      {/* Divider */}

      <div className="field col-12 md:col-7">
        <div className="field checkbox-container col-12 md:mb-3 custom-target-tooltip">
          <Tooltip target=".custom-target-tooltip" />
          <Checkbox
            name="empfatautEmp"
            value={form.values.empfatautEmp}
            onChange={form.handleChange}
            checked={form.values.empfatautEmp}
            disabled={!edicaoHabilitada}
            tooltipOptions={{ position: 'top' }}
            tooltip='Ao desabilitar essa opção, pode ser alterado a empresa de faturamento!'
            className='custom-target-tooltip'
          />
          <label className="p-checkbox-label mb-0 pl-3">Definir empresa de faturamento automaticamente?</label>
        </div>
        <div className="field col-12 md:col-12">
          <SearchInput
            // props
            className="inputfield w-full"
            label="Empresa de Faturamento"
            disabled={!edicaoHabilitada || !!form.values.empfatautEmp}

            // Controle do valor e seleção da empresa de faturamento
            value={typeof empFaturamentoSelecionada === 'string' ? empFaturamentoSelecionada : empFaturamentoSelecionada?.nomfan_emp}
            onSelect={handleChangeOrSelectEmpFaturamento}

            // Controle de sugestão do autocomplete
            suggestions={sugestaoEmpFaturamento}
            completeMethod={sugerirEmpFaturamento}
            onChange={handleChangeOrSelectEmpFaturamento}

            // controle do FiltroModal
            filtroTitle="Pesquisa de empresas de faturamento"
            service={EmpresaService}
            model={getEmpresaDTO}
            columns={[
              { campo: 'codigo_emp', nome: 'Código' },
              { campo: 'nomfan_emp', nome: 'Nome' },
              { campo: 'cnpj_emp', nome: 'CPF/CNPJ', format: formatCpfOrCnpj }
            ]}
          />
        </div>
      </div>
    </div>
  )
}

export default DadosPrincInfoFinanceira
