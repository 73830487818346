import ServiceBase from '../base/ServiceBase'

export default class DespachanteService {
  static async convidarLojista (emailLojista, nomeLojista, codigoEmpresa) {
    return await ServiceBase.postBase('despachante/convidarLojista', { emailLojista, nomeLojista, codigoEmpresa })
  }

  static async reconvidarLojista (codigoConvite) {
    return await ServiceBase.postBase('despachante/reconvidarLojista', { codigoConvite })
  }
}
