import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import AccordionContainer from '../../../components/layout/AccordionContainer'
import AccordionPage from '../../../components/layout/AccordionPage'
import Container from '../../../components/layout/Container'
import { baseForm } from '../../../components/utils/BaseForm'
import Confirm from '../../../components/utils/Confirm'
import { showErrorMessage, showSuccessMessage, showWarnMessage } from '../../../components/utils/Message'
import CommonHelper from '../../../helpers/CommonHelper'
import { setEmpresaLogada } from '../../../redux/actions'
import { dispatch, getStore } from '../../../redux/store'
import EmpresaService from '../../../services/cadastro/EmpresaService'
import ConfigService from '../../../services/config/ConfigService'
import DadosDespachanteEmp from './DadosDespachanteEmp'
import DadosPrincipaisEmp from './DadosPrincipaisEmp'
import { EmpresaValidator } from './EmpresaValidator'

const EmpresaForm = ({ isNew, model, onDelete }) => {
  const [isNovo, setIsNovo] = useState(isNew)
  const [edicaoHabilitada, setEdicaoHabilitada] = useState(false)
  const [visibleConfirm, setVisibleConfirm] = useState(false)
  const history = useHistory()

  useEffect(() => {
    if (isNovo) setEdicaoHabilitada(true)
  }, [])

  const handleSubmit = async (data) => {
    try {
      const empresa = await EmpresaService.save(data)
      await ConfigService.createConfigEmpAndConfigGru(empresa.codigo_emp)

      const empresaLogada = getStore().empresaLogada

      if (empresaLogada.codigo_emp === empresa.codigo_emp) {
        const empresaAtualizada = {
          ...empresa,
          descri_mun: form.values.municipio.descri_mun,
          descri_uf: form.values.estado.descri_uf,
          sigla_uf: form.values.estado.sigla_uf
        }
        dispatch(setEmpresaLogada(empresaAtualizada))
      }

      history.push(`/empresa/${empresa.codigo_emp}`)

      form.setFieldValue('codigoEmp', empresa.codigo_emp)
      form.setFieldValue('datdesEmp', empresa.datdes_emp)

      setIsNovo(false)
      showSuccessMessage('Registro salvo com sucesso')
      setEdicaoHabilitada(false)
    } catch (error) {
      showErrorMessage(error.message || 'Erro ao salvar registro')
    }
  }

  const submitForm = async (e) => {
    e.preventDefault()
    const errors = await form.validateForm(form.values)

    if (Object.keys(errors).length !== 0) {
      form.handleSubmit(e)
      showWarnMessage('Campos obrigatórios não preenchidos!')
    } else {
      form.handleSubmit(e)
    }
  }

  const deleteForm = async () => {
    onDelete(form.values.codigoEmp)
  }

  const form = baseForm({
    initialValues: model,
    validationSchema: EmpresaValidator(),
    onSubmit: handleSubmit
  })

  function getAbaDespachante () {
    if (!CommonHelper.isUsuarioDespachante()) {
      return <AccordionPage header="Informações do despachante" active>
        <DadosDespachanteEmp
          form={form}
          edicaoHabilitada={edicaoHabilitada}
        />
      </AccordionPage>
    }

    return
  }

  return (
    <Container col="12">
      <form onSubmit={submitForm}>
        <div className="flex justify-content-between form-header">
          <h1>Empresa</h1>
          {!isNovo && (
            <div className="flex justify-content-between">
              <Button
                label="Excluir"
                icon="pi pi-times"
                type="button"
                onClick={() => setVisibleConfirm(true)}
                className="p-button-danger mr-2"
              />
              <Button
                type="button"
                label="Editar"
                className="edit-button"
                icon="pi pi-pencil"
                onClick={() => setEdicaoHabilitada(!edicaoHabilitada)}
              />
            </div>
          )}
        </div>
        <div className="grid form-body">
          <AccordionContainer className={CommonHelper.isUsuarioDespachante() ? 'unique' : ''}>
            <AccordionPage header="Informações principais" active>
              <DadosPrincipaisEmp
                form={form}
                edicaoHabilitada={edicaoHabilitada}
              />
            </AccordionPage>
            {getAbaDespachante()}
          </AccordionContainer>
        </div>
        <div className="flex justify-content-end form-footer">
          {edicaoHabilitada && (
            <Button
              type="submit"
              label="Salvar"
              className="p-button"
            />
          )}
        </div>
      </form>
      <Confirm
        visible={visibleConfirm}
        onConfirm={() => deleteForm()}
        onCancel={() => setVisibleConfirm(false)}
        title="Confirmação"
        description="Deseja realmente excluir este registro?"
      />
    </Container>
  )
}

export default EmpresaForm
