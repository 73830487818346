import { Button } from 'primereact/button'
import React, { useState } from 'react'
import TextInput from '../../components/inputs/TextInput'
import { showErrorMessage, showSuccessMessage, showWarnMessage } from '../../components/utils/Message'
import Modal from '../../components/utils/Modal'
import { isValidEmail } from '../../helpers/checkers'
import { getStore } from '../../redux/store'
import DespachanteService from '../../services/despachante/DespachanteService'

function ConvidarLojistaModal ({ visible, onHide, onFilter }) {
  const [emailLojista, setEmailLojista] = useState('')
  const [nomeLojista, setNomeLojista] = useState('')
  const codigoEmpresa = getStore().empresaLogada.codigo_emp

  function hideModal () {
    setEmailLojista('')
    setNomeLojista('')
    onHide()
  }

  async function enviarConviteLojista () {
    try {
      if (!isValidEmail(emailLojista)) {
        showWarnMessage('Por favor informe um email valido!')
        return
      }

      // if (!getStore().usuarioLogado.cpf_usu) {
      //   showWarnMessage('Por favor informe o CPF do usuário, editando o registro do usuario logado na tela de usuários!')
      //   return
      // }

      await DespachanteService.convidarLojista(emailLojista, nomeLojista, codigoEmpresa)

      hideModal()

      showSuccessMessage('Convite enviado com sucesso!')

      if (onFilter) {
        await onFilter()
      }
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar enviar o convite para o lojista!')
    }
  }

  return (
    <Modal
      visible={visible}
      onHide={hideModal}
      width={50}
      header="Convidar lojista"
      footer={
        <Button
          label="Enviar"
          className="p-button"
          onClick={async () => await enviarConviteLojista()}
        />
      }
    >
      <div className="formgrid grid">
        <div className="field col-12 md:col-12">
          <TextInput
            value={emailLojista}
            label="Email lojista"
            onChange={(e) => setEmailLojista(e.target.value)}
            className="inputfield w-full"
            placeholder="Email do lojista"
            autofocus
          />
        </div>
        <div className="field col-12 md:col-12">
          <TextInput
            value={nomeLojista}
            label="Nome lojista"
            onChange={(e) => setNomeLojista(e.target.value)}
            className="inputfield w-full"
            placeholder="Nome do lojista"
          />
        </div>
      </div>
    </Modal>
  )
}

export default ConvidarLojistaModal
